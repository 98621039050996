import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  DialogTitleProps,
  DialogContentProps,
  DialogActionsProps,
  DialogProps,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

export interface BaseDialogProps extends DialogProps {
  open: boolean;
  title?: string;
  body?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  classes?: {
    root?: string;
    title?: string;
    body?: string;
    footer?: string;
  };
  titleProps?: DialogTitleProps;
  bodyProps?: DialogContentProps;
  footerProps?: DialogActionsProps;
  onClose: () => void;
}

const Root = styled(Dialog)(({ theme }) => ({
  '& .btn-close': {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
  '& .MuiDialog-paper': {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1400,
    },

    '& .MuiDialogContent-root': {
      position: 'relative',
    },
  },
}));

export const BaseDialog: FC<BaseDialogProps> = (props) => {
  const {
    open,
    title,
    body,
    footer,
    classes,
    titleProps,
    bodyProps,
    footerProps,
    onClose,
    ...rest
  } = props;

  return (
    <Root
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      className={classes?.root}
      open={open}
      {...rest}
    >
      <DialogTitle {...titleProps} className={classes?.title}>
        <IconButton aria-label="close" onClick={onClose} className="btn-close">
          <CloseIcon />
        </IconButton>
        {title}
      </DialogTitle>
      {body != null && (
        <DialogContent {...bodyProps} className={classes?.body}>
          {body}
        </DialogContent>
      )}
      {footer != null && (
        <DialogActions {...footerProps} className={classes?.footer}>
          {footer}
        </DialogActions>
      )}
    </Root>
  );
};
