import { Ref, forwardRef } from 'react';

import { MenuItem, Select, SelectProps, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { colors } from '@/theme';

import { BaseInput } from '../BaseInput';
import { SelectItemProps } from '../types';

interface BaseSelectProps extends SelectProps<string> {
  items: SelectItemProps[];
  label: string;
  placeholder?: string;
}

export const BaseSelect = forwardRef<Ref<any>, BaseSelectProps>(
  (props, rootRef) => {
    const { items, label, value, ...rest } = props;
    const classes = styles();

    return (
      <Select
        className={clsx(classes.root, !value && classes.placeholder)}
        input={<BaseInput ref={rootRef} label={label} />}
        value={value}
        {...rest}
      >
        {items.map((item) => (
          <MenuItem
            key={`${item.label}-${item.value}`}
            value={item.value}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
  },
);

BaseSelect.displayName = 'Base Select';

const styles = makeStyles(() => ({
  root: {
    height: 46,
    width: 300,
    borderRadius: 6,
    backgroundColor: alpha('#FFFFFF', 1),
    position: 'relative',
    marginRight: 10,

    '& .MuiSelect-select': {
      color: colors.grey[600],
    },
  },
  placeholder: {
    '& .MuiSelect-select': {
      color: colors.grey[500],
      opacity: 0.6,
    },
  },
}));
