import { AccountInfo } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  accessToken: string | null;
  account: AccountInfo | null;
}

const initialState: AuthState = {
  accessToken: '',
  account: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLocalAccount(state: AuthState, action: PayloadAction<AuthState>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    logout() {
      return { ...initialState };
    },
  },
});

export const selectUsername = (state: { auth: AuthState }) =>
  state.auth.account?.username;

export const selectAccessToken = (state: { auth: AuthState }) =>
  state.auth.accessToken;

export const { setLocalAccount, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
