import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_APPLICATION_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const azureLoginRequest = {
  scopes: [
    'openid',
    process.env.REACT_APP_AZURE_AD_APPLICATION_API_SCOPE as string,
  ],
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, azureLoginRequest };
