import { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import { wrapUseRoutes } from '@sentry/react';

import ProtectedRoute from './PrivateRoute';
import { MainLayout } from '../layouts';

const useSentryRoutes = wrapUseRoutes(useRoutes);

const LoginScreen = lazy(async () => await import('@/pages/Login'));
const AccountScreen = lazy(async () => await import('@/pages/Account'));
const AgencyScreen = lazy(async () => await import('@/pages/Agency'));

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginScreen />,
  },
  {
    path: '',
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <AccountScreen />,
      },
      {
        path: '/agency',
        element: <AgencyScreen />,
      },
    ],
  },
];

export const AppRouter = () => {
  return useSentryRoutes(routes);
};
