import { FC, ReactNode } from 'react';

import { Box, styled, Typography } from '@mui/material';

interface PageHeaderProps {
  title: string;
  children?: ReactNode;
}

const Root = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',

  '& .left': {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
}));

export const PageHeader: FC<PageHeaderProps> = ({ title, children }) => {
  return (
    <Root>
      <Typography variant="h5" color="white" fontWeight={700}>
        {title}
      </Typography>
      <Box className="left">{children}</Box>
    </Root>
  );
};
