import { DrawingManager, PolygonF } from '@react-google-maps/api';

import { useMapContext } from '@/contexts';
import { IPolygon } from '@/models';
import { colors } from '@/theme';

const polygonOptions = {
  fillColor: colors.primary.light,
  strokeColor: colors.primary.main,
  editable: true,
  zIndex: 1,
};

interface DrawContronProps extends google.maps.drawing.DrawingManagerOptions {
  polygonData?: IPolygon | null;
  onPolygonComplete?: (newPolygonData: IPolygon) => void;
}

export const DrawControl = (props: DrawContronProps) => {
  const { polygonData, onPolygonComplete, ...rest } = props;
  const { map } = useMapContext();
  const polygonPath = polygonData?.coordinates[0].map((coordinate) => ({
    lng: coordinate[0],
    lat: coordinate[1],
  })) || [[]];

  const handleLoad = () => {
    console.log('-------- draw controller is loaded ---------');
  };

  const handlePolygonComplete = (polygon: google.maps.Polygon) => {
    console.log(polygon);
    const polygonBounds = polygon.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.getLength(); i++) {
      const point = [
        polygonBounds.getAt(i).lng(),
        polygonBounds.getAt(i).lat(),
      ];
      bounds.push(point);
    }
    if (bounds[0] != bounds[bounds.length - 1]) {
      bounds.push(bounds[0]);
    }
    if (onPolygonComplete) {
      onPolygonComplete({ type: 'Polygon', coordinates: [bounds] });
      polygon.setPaths([[]]);
    }
  };

  return (
    <>
      <DrawingManager
        options={{
          map,
          drawingControlOptions: {
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            ...polygonOptions,
            editable: false,
          },
        }}
        {...rest}
        onLoad={handleLoad}
        onPolygonComplete={handlePolygonComplete}
      />
      <PolygonF
        editable
        paths={polygonPath}
        options={polygonOptions}
        onEdit={handlePolygonComplete}
      />
    </>
  );
};
