import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { baseURL } from '@/config';
import { store } from '@/store';
import { logout } from '@/store/authSlice';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': true,
  'X-Requested-With': 'XMLHttpRequest',
};

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL,
      headers,
      withCredentials: true,
    });

    http.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { response } = error;
        return await this.handleError(response);
      },
    );

    this.instance = http;
    return http;
  }

  async request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return await this.http.request(config);
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this.http.get<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this.http.post<T, R>(url, data, config);
  }

  async put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this.http.put<T, R>(url, data, config);
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return await this.http.delete<T, R>(url, config);
  }

  private async handleError(error: AxiosError) {
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        store.dispatch(logout());
        this.setToken(null);
        break;
      }
      case StatusCode.Unauthorized: {
        store.dispatch(logout());
        this.setToken(null);
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }

    return await Promise.reject(error);
  }

  setToken(accessToken: string | null) {
    if (this.instance == null) {
      this.initHttp();
    }
    if (this.instance != null) {
      const newToken = `Bearer ${accessToken}`;
      const currentHeaders = this.instance.defaults.headers.common;
      if (newToken !== currentHeaders.Authorization) {
        this.instance.defaults.headers.common = {
          ...axios.defaults.headers.common,
          Authorization: newToken,
        };
      }
    }
  }

  setTenant(subdomain?: string | null) {
    if (this.instance != null) {
      this.instance.defaults.headers.common = {
        ...this.instance.defaults.headers.common,
        'X-HELPTAPP-Account': subdomain,
      };
    }
  }
}

export const http = new Http();
