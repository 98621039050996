import { createContext, ReactNode, useContext, useState } from 'react';

interface MapContextData {
  map?: google.maps.Map;
}

interface MapContextValue extends MapContextData {
  setMap: (map?: google.maps.Map) => void;
}

interface MapProviderProps {
  children: ReactNode;
}

export const MapContext = createContext<MapContextValue>({
  setMap: () => {},
});

export const MapContextProvider = (props: MapProviderProps) => {
  const { children } = props;
  const [map, setMap] = useState<google.maps.Map>();

  return (
    <MapContext.Provider
      value={{
        map,
        setMap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
