import { colors } from './variables';

export default {
  fontFamily: 'Roboto, sans-serif',
  color: colors.grey[500],
  allVariants: {
    color: colors.grey[500],
  },
  body2: {
    fontSize: '0.9375rem',
  },
};
