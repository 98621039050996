import { FC, ReactElement, useLayoutEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { http } from '@/apis';
import { useAppSelector } from '@/hooks';
import { selectAccessToken } from '@/store';

interface ProtectedRouteProps {
  children: ReactElement<any, any> | null;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const accessToken = useAppSelector(selectAccessToken);
  const location = useLocation();

  useLayoutEffect(() => {
    if (accessToken != null) {
      http.setToken(accessToken);
    }
  }, [accessToken]);

  if (!accessToken) {
    return (
      <Navigate
        to="/login"
        replace
        state={{
          path: location.pathname,
        }}
      />
    );
  }
  return children;
};

export default ProtectedRoute;
