import { colors } from '../variables';

export default {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        fontSize: '1rem',
        borderRadius: 6,
        textTransform: 'none' as const,
        boxShadow: 'none',
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'inherit' && {
            border: `1px solid ${colors.grey[300]}`,
            backgroundColor: colors.white,
            color: colors.grey[500],

            '&:hover': {
              backgroundColor: colors.grey.hover,
              boxShadow: 'none',
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' && {
            '&:hover': {
              backgroundColor: colors.primary.hover,
              boxShadow: 'none',
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'error' && {
            '&:hover': {
              backgroundColor: colors.error.hover,
              boxShadow: 'none',
            },
          }),
      }),
    },
  },
};
