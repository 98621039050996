import { styled } from '@mui/material';

export const FormRoot = styled('form')(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  overflowY: 'auto',
}));
