import { FC } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, ButtonProps, Stack, Typography } from '@mui/material';

import { BaseDialog, BaseDialogProps } from '../BaseDialog';

export enum ConfirmTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface ConfirmDialogProps extends BaseDialogProps {
  type?: ConfirmTypes;
  title?: string;
  message: string;
  btnConfirmText?: string;
  btnConfirmProps?: ButtonProps;
  btnCancelProps?: ButtonProps;
  btnCancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = (props) => {
  const {
    type,
    title,
    message,
    btnConfirmText = 'Ok',
    btnCancelText = 'Cancel',
    btnConfirmProps,
    btnCancelProps,
    onCancel,
    onConfirm,
    ...rest
  } = props;

  const headerIcon =
    type === ConfirmTypes.SUCCESS ? (
      <CheckCircleOutlineIcon
        color={type}
        sx={{
          width: '40px',
          height: '40px',
          mb: 2,
        }}
      />
    ) : (
      <ErrorOutlineIcon
        color={type}
        sx={{
          width: '40px',
          height: '40px',
          mb: 2,
        }}
      />
    );

  const renderBody = () => {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ width: 400 }}>
        {headerIcon}
        <Typography variant="h6">{title}</Typography>
        <Typography>{message}</Typography>
        <Stack flexDirection="row" sx={{ mt: 4, width: '100%' }}>
          {onConfirm != null && (
            <Button
              color="primary"
              onClick={onConfirm}
              variant="contained"
              sx={{ flex: 1, mr: 1 }}
              {...btnConfirmProps}
            >
              {btnConfirmText}
            </Button>
          )}
          {onCancel != null && (
            <Button
              color="inherit"
              onClick={onCancel}
              variant="contained"
              sx={{ flex: 1, ml: 1 }}
              {...btnCancelProps}
            >
              {btnCancelText}
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  return <BaseDialog body={renderBody()} {...rest} />;
};
