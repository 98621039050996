import React, { FC } from 'react';
import { Controller, useFormContext, FieldError, get } from 'react-hook-form';

import {
  FormHelperText,
  SelectChangeEvent,
  SelectProps,
  styled,
} from '@mui/material';

import { BaseSelect } from '../BaseSelect';
import { FormElementProps, SelectItemProps } from '../types';

export interface FormSelectFieldProps
  extends Omit<SelectProps, 'name' | 'onChange'>,
    FormElementProps {
  label: string;
  half?: boolean;
  items?: SelectItemProps[];
  handleChange?: (name: string, value: string | number) => void;
}

const Root = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiInputBase-root': {
    width: '100%',
    height: 44,
  },
  '&.half': {
    minWidth: 'calc(50% - 10px)',
    maxWidth: 'calc(50% - 10px)',
  },
}));

export const FormSelectField: FC<FormSelectFieldProps> = (props) => {
  const {
    half,
    name,
    rules,
    items = [],
    disabled,
    handleChange,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Root className={half ?? false ? 'half' : ''}>
      <Controller
        control={control}
        name={name}
        render={({ field }: any) => {
          return (
            <BaseSelect
              {...rest}
              {...field}
              name={name}
              onChange={(event: SelectChangeEvent) => {
                field.onChange(event);
                if (handleChange != null)
                  handleChange(event.target.name, event.target.value);
              }}
              disabled={disabled}
              items={items}
            />
          );
        }}
        rules={rules}
        defaultValue=""
      />
      {
        <FormHelperText error={!(error == null)}>
          {error?.message}
        </FormHelperText>
      }
    </Root>
  );
};
