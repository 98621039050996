import { styled, TableContainer as MuiTableContainer } from '@mui/material';
import { type TableCellProps } from '@mui/material';

export interface ColumnProps {
  title: string;
  columnProps?: TableCellProps;
}

export const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  flex: 1,
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)',
  '& table': {
    height: '100%',
    '& td': {
      color: theme.palette.grey[500],
    },
  },
  '& thead': {
    '& th': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
}));
