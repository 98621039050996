import { FC } from 'react';

import { FormAutoCompleteField } from './FormAutoCompleteField';
import { FormInputField, FormInputFieldProps } from './FormInputField';
import { FormSelectField, FormSelectFieldProps } from './FormSelectField';

export const RenderFormField: FC<FormInputFieldProps & FormSelectFieldProps> = (
  props,
) => {
  const { type } = props;

  if (type === 'select') {
    return <FormSelectField {...props} />;
  }

  if (type === 'autocomplete') {
    return <FormAutoCompleteField {...props} />;
  }

  return <FormInputField {...props} />;
};
