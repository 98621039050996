import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { IAccount, IAccountForm } from '@/models';

import { http } from './http';
import { GetParamsType } from './types';

export const AccountApi = {
  create: async (data: IAccountForm): Promise<AxiosResponse<IAccount>> => {
    return await http.post(`/api/${apiVersion}/accounts/`, data);
  },
  get: async (params?: GetParamsType): Promise<AxiosResponse> => {
    return await http.get(`/api/${apiVersion}/accounts/`, { params });
  },

  getOne: async (accountId: string): Promise<AxiosResponse<IAccount>> => {
    return await http.get(`/api/${apiVersion}/accounts/${accountId}`);
  },
  update: async ({
    _id,
    ...data
  }: IAccountForm): Promise<AxiosResponse<IAccount>> => {
    return await http.put(`/api/${apiVersion}/accounts/${_id}`, data);
  },
  delete: async (accountId: string): Promise<AxiosResponse> => {
    return await http.delete(`/api/${apiVersion}/accounts/${accountId}/`);
  },
};
