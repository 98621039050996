import { colors } from '../variables';

export default {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: colors.grey[500],
        fontSize: '0.9375rem',
        fontWeight: 500,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: colors.grey[300],
        borderStyle: 'solid',
        borderRadius: 6,
        padding: '5px 10px',
        '& input': {
          '&::placeholder': {
            color: colors.grey[500],
            opacity: 0.6,
          },
        },
      },
    },
  },
};
