import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, styled, useTheme } from '@mui/material';

import { NavBar } from '../NavBar';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  padding: '0px 15px',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    padding: '0px 30px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '0px 45px',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0px 60px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0px 144px',
  },
}));

const HeaderSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 311,
  backgroundColor: theme.palette.primary.dark,
  zIndex: -1,
}));

const Main = styled('main')(() => ({
  flex: 1,
  width: '100%',
}));

export const MainLayout: FC = () => {
  const theme = useTheme();
  return (
    <Root>
      <HeaderSection />
      <NavBar />
      <Main theme={theme}>
        <Outlet />
      </Main>
    </Root>
  );
};
