import { FC, useMemo } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import {
  Autocomplete,
  FormHelperText,
  InputLabel,
  TextField,
  styled,
} from '@mui/material';

import { colors } from '@/theme';

import { BaseInputProps } from '../BaseInput';
import { FormElementProps, SelectItemProps } from '../types';

export interface FormAutoCompleteFieldProps
  extends Omit<BaseInputProps, 'name' | 'onChange'>,
    FormElementProps {
  items?: SelectItemProps[];
  handleChange?: (name: string, value: string | number) => void;
}

const Root = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiInputBase-root': {
    width: '100%',
    height: 44,
    borderWidth: 0,
    borderRadius: 6,

    '& input': {
      boxSizing: 'content-box!important',
      padding: 0,
    },
  },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: 0,

    '&: .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.grey[100],
    },
  },
  '&.half': {
    minWidth: 'calc(50% - 10px)',
    maxWidth: 'calc(50% - 10px)',
  },
}));

export const FormAutoCompleteField: FC<FormAutoCompleteFieldProps> = (
  props,
) => {
  const { name, label, half, rules, items = [] } = props;
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  const value = watch(name);

  const currentItem = useMemo(() => {
    return (
      items.find((item) => value === item.value) || { value: '', label: '' }
    );
  }, [value]);

  return (
    <Root className={half ?? false ? 'half' : ''}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }: any) => (
          <Autocomplete
            autoComplete
            onChange={(event, item) => {
              onChange(item?.value ?? '');
            }}
            defaultValue={items[0]}
            value={currentItem}
            getOptionLabel={(item) => (item.label ? item.label : '')}
            options={items}
            renderInput={(params) => (
              <>
                <InputLabel>{label}</InputLabel>
                <TextField
                  {...params}
                  name={name}
                  value={value}
                  placeholder={props.placeholder}
                />
              </>
            )}
          />
        )}
        rules={rules}
      />
      {
        <FormHelperText error={!(error == null)}>
          {error?.message}
        </FormHelperText>
      }
    </Root>
  );
};
