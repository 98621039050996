import { ChangeEvent, FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { FormHelperText, styled } from '@mui/material';

import { BaseInput, BaseInputProps } from '../BaseInput';
import { FormElementProps } from '../types';

export interface FormInputFieldProps
  extends Omit<BaseInputProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: string | number) => void;
}

const Root = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiInputBase-root': {
    width: '100%',

    '& input': {
      boxSizing: 'content-box!important',
    },
  },
  '&.half': {
    minWidth: 'calc(50% - 10px)',
    maxWidth: 'calc(50% - 10px)',
  },
}));

export const FormInputField: FC<FormInputFieldProps> = (props) => {
  const { name, half, rules, defaultValue, handleChange, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Root className={half ?? false ? 'half' : ''}>
      <Controller
        control={control}
        defaultValue={Boolean(defaultValue) || ''}
        name={name}
        render={({ field }: any) => (
          <BaseInput
            {...rest}
            {...field}
            name={name}
            onChange={(
              event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              field.onChange(event);
              if (handleChange != null)
                handleChange(event.target.name, event.target.value);
            }}
          />
        )}
        rules={rules}
      />
      {
        <FormHelperText error={!(error == null)}>
          {error?.message}
        </FormHelperText>
      }
    </Root>
  );
};
