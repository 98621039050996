export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    50: '#EFF6FF',
    100: '#DBEAFE',
    light: '#BFDBFE',
    300: '#93C5FD',
    400: '#60A5FA',
    500: '#3B82F6',
    main: '#5586FF',
    hover: '#2F68C5',
    700: '#1D42D8',
    800: '#1E40AF',
    dark: '#203E86',
  },
  info: {
    50: '#E6FAF8',
    100: '#CCF4F1',
    light: '#6DCCC4',
    300: '#66DFD5',
    400: '#33D4C6',
    500: '#20C9B8',
    main: '#1CB5A5',
    700: '#1AAB9D',
    800: '#138D81',
    dark: '#0F796F',
  },
  error: {
    50: '#FCEEF0',
    100: '#FADCE1',
    light: '#F4B9C2',
    300: '#F096A5',
    400: '#EA7386',
    500: '#E55068',
    main: '#F42F5E',
    hover: '#C3264B',
    700: '#C34459',
    800: '#A03849',
    dark: '#8A303F',
  },
  secondary: {
    50: '#EEEEFC',
    100: '#DCDDFA',
    light: '#B9BBF4',
    300: '#969AF0',
    400: '#7378EA',
    500: '#5056E5',
    main: '#484DCE',
    700: '#4449C3',
    800: '#383CA0',
    dark: '#30348A',
  },
  warning: {
    50: '#FFF6EA',
    100: '#FEEDD3',
    light: '#FDDBA7',
    300: '#FEC97C',
    400: '#FACC15',
    500: '#FCA528',
    main: '#E29423',
    700: '#D78D21',
    800: '#B07319',
    dark: '#986316',
  },
  grey: {
    hover: '#FAFBFC',
    50: '#EFF0F4',
    100: '#F1F5F9',
    200: '#D1D5D8',
    300: '#CBD5E1',
    400: '#8088A3',
    500: '#264658',
    600: '#475569',
    700: '#525A77',
    800: '#1E293B',
    900: '#3A4054',
  },
};
