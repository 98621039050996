import { forwardRef, Ref } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';

import { InputBase, InputLabel, InputBaseProps } from '@mui/material';

export interface BaseInputProps extends Omit<InputBaseProps, 'value'> {
  label: string;
  value?: string;
  mask?: Mask | ((value: string) => Mask);
  half?: boolean;
}

export const BaseInput = forwardRef<Ref<any>, BaseInputProps>(
  (props, rootRef) => {
    const { label, mask, ...rest } = props;

    return (
      <>
        <InputLabel>{label}</InputLabel>
        <MaskedInput
          mask={mask ?? false}
          onChange={props.onChange}
          render={(ref, { onChange }) => (
            <InputBase
              {...rest}
              inputRef={ref}
              ref={rootRef}
              onChange={onChange}
            />
          )}
          guide={false}
        />
      </>
    );
  },
);

BaseInput.displayName = 'BaseInput';
