import { styled } from '@mui/material';

export const Page = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  marginTop: 30,
}));
