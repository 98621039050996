import { SnackbarOrigin } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConfirmTypes } from '@/components';

interface ToastState extends SnackbarOrigin {
  open?: boolean;
  type?: ConfirmTypes;
  message?: string;
  onClose?: () => void;
}

const initialState: ToastState = {
  open: false,
  type: ConfirmTypes.SUCCESS,
  message: '',
  vertical: 'top',
  horizontal: 'right',
};

export const toastSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    showToast(state: ToastState, action: PayloadAction<Partial<ToastState>>) {
      return {
        ...state,
        ...action.payload,
        open: true,
      };
    },
    hideToast() {
      return {
        ...initialState,
        open: false,
      };
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export const toastReducer = toastSlice.reducer;
