import { createContext, FC, ReactNode, useState } from 'react';

import { IAccount, IAgencyForm, IUser } from '@/models';

interface AccountDataFields {
  account?: IAccount;
  agencies?: IAgencyForm[];
  admins?: IUser[];
}

interface AccountContextValue extends AccountDataFields {
  updateData: (data: AccountDataFields) => void;
}

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountContext = createContext<AccountContextValue>({
  agencies: [],
  admins: [],
  updateData: () => {},
});

export const AccountContextProvider: FC<AccountProviderProps> = (props) => {
  const { children } = props;
  const [values, setValues] = useState<AccountDataFields>();

  const updateData = (data: AccountDataFields) => {
    setValues((v) => ({ ...v, ...data }));
  };

  return (
    <AccountContext.Provider
      value={{
        ...values,
        updateData,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
