import { FC, useRef } from 'react';

import { Autocomplete } from '@react-google-maps/api';

import { IPoint } from '@/models';

import { SearchBar } from '../SearchBar';

interface SearchAddressBarProps {
  address: string;
  onChange: (address: string, position?: IPoint) => void;
}

export const SearchAddressBar: FC<SearchAddressBarProps> = ({
  address,
  onChange,
}) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();

  const handleLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };

  const handlePlaceChanged = () => {
    const selectedPlace = autoCompleteRef.current?.getPlace();
    if (selectedPlace) {
      const position = selectedPlace.geometry?.location?.toJSON();
      onChange(
        selectedPlace.formatted_address ?? '',
        position
          ? { type: 'Point', coordinates: [position.lng, position.lat] }
          : undefined,
      );
    }
  };

  return (
    <Autocomplete
      restrictions={{ country: 'us' }}
      onLoad={handleLoad}
      onPlaceChanged={handlePlaceChanged}
      // browserAutofillEnabled
      // confirmOnBrowserAutofill
    >
      <SearchBar
        value={address}
        placeholder="Search address..."
        onChange={(str: string) => {
          onChange(str);
        }}
      />
    </Autocomplete>
  );
};
