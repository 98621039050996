import { colors } from './variables';

export default {
  ...colors,
  background: {
    paper: colors.white,
    default: colors.grey[100],
  },
  color: {
    default: colors.grey[500],
  },
};
