import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { IAdminForm } from '@/models';

import { http } from './http';
import { GetParamsType } from './types';

export const AdminApi = {
  create: async (data: IAdminForm): Promise<AxiosResponse> => {
    return await http.post(`/api/${apiVersion}/users/`, data);
  },
  get: async (params?: GetParamsType): Promise<AxiosResponse> => {
    return await http.get(`/api/${apiVersion}/users/`, { params });
  },
  resendVerificationEmail: async (userId: string): Promise<AxiosResponse> => {
    return await http.post(
      `/api/${apiVersion}/users/${userId}/send-verification-email`,
    );
  },
};
