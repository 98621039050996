import { AxiosResponse } from 'axios';

import { apiVersion } from '@/config';
import { IAgency } from '@/models';

import { http } from './http';
import { GetParamsType } from './types';

export const AgencyApi = {
  create: async (data: IAgency, accountId?: string): Promise<AxiosResponse> => {
    return await http.post(`/api/${apiVersion}/agencies/`, {
      ...data,
      account: accountId,
    });
  },
  update: async (
    { _id, ...rest }: IAgency,
    accountId?: string,
  ): Promise<AxiosResponse> => {
    return await http.put(`/api/${apiVersion}/agencies/${_id}/`, {
      ...rest,
      account: accountId,
    });
  },
  get: async (params?: GetParamsType): Promise<AxiosResponse> => {
    return await http.get(`/api/${apiVersion}/agencies/`, { params });
  },
  getOne: async (id: string): Promise<AxiosResponse> => {
    return await http.get(`/api/${apiVersion}/agencies/${id}/`);
  },
  delete: async (id: string): Promise<AxiosResponse> => {
    return await http.delete(`/api/${apiVersion}/agencies/${id}/`);
  },
};
