import { memo, PropsWithChildren, useCallback, useEffect } from 'react';

import { GoogleMap, MarkerF } from '@react-google-maps/api';

import { defaultMapOptions, googleMapID } from '@/config';
import { MapContextProvider, useMapContext } from '@/contexts';
import { IPoint } from '@/models';

const containerStyle = {
  flex: 1,
  padding: '0px 20px',
};

export interface BaseMapProps extends PropsWithChildren {
  centerPoint?: IPoint;
}

const BaseMapComp = ({ centerPoint, children }: BaseMapProps) => {
  const { map, setMap } = useMapContext();
  const isLoaded = window.google;

  useEffect(() => {
    if (map && centerPoint) {
      map.setCenter({
        lat: centerPoint.coordinates[1],
        lng: centerPoint.coordinates[0],
      });
    }
  }, [map, centerPoint]);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setOptions(defaultMapOptions);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap();
  }, []);

  return isLoaded ? (
    <GoogleMap
      id={googleMapID}
      mapContainerStyle={containerStyle}
      {...defaultMapOptions}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {!!centerPoint && (
        <MarkerF
          position={{
            lat: centerPoint.coordinates[1],
            lng: centerPoint.coordinates[0],
          }}
        />
      )}
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

const BaseMap = (props: BaseMapProps) => (
  <MapContextProvider>
    <BaseMapComp {...props} />
  </MapContextProvider>
);

export default memo(BaseMap);
