type Stage = 'local' | 'test' | 'dev' | 'staging' | 'prod';
const stage: Stage = (process.env.REACT_APP_STAGE || 'local') as Stage;

const baseURL: string = process.env.REACT_APP_API_HOST as string;
const apiVersion: string = process.env.REACT_APP_API_VERSION as string;
const googleMapsApiKey: string = process.env
  .REACT_APP_GOOGLE_MAPS_API_KEY as string;
const googleMapID: string = process.env.REACT_APP_GOOGLE_MAP_ID as string;
const postLogoutRedirectUrl: string = process.env
  .REACT_APP_POST_LOGOUT_REDIRECT_URI as string;

const defaultMapOptions = {
  center: {
    lat: 41.149,
    lng: -73.983,
  },
  zoom: 13,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
};

export {
  stage,
  apiVersion,
  baseURL,
  postLogoutRedirectUrl,
  googleMapsApiKey,
  googleMapID,
  defaultMapOptions,
};
