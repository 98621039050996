import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { MsalProvider } from '@azure/msal-react';
import { Library } from '@googlemaps/js-api-loader';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useJsApiLoader } from '@react-google-maps/api';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';

import { Toast } from './components';
import { googleMapID, googleMapsApiKey } from './config';
import { AppRouter } from './router';
import { msalInstance } from './services';
import { persistor, store } from './store';
import { customTheme } from './theme';

const mapLibraries = ['places', 'drawing'];

function App() {
  const theme = createTheme(customTheme);

  useJsApiLoader({
    id: googleMapID,
    libraries: mapLibraries as Library[],
    googleMapsApiKey,
  });

  return (
    <Sentry.ErrorBoundary>
      <Suspense>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <MsalProvider instance={msalInstance}>
                <CssBaseline />
                <Box height="aaa" display="flex" flexDirection="column">
                  <Toast />
                  <BrowserRouter>
                    <AppRouter />
                  </BrowserRouter>
                </Box>
              </MsalProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
