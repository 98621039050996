import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  styled,
  Tab,
  Tabs,
} from '@mui/material';

import { postLogoutRedirectUrl } from '@/config';
import { useAppSelector } from '@/hooks';
import { selectUsername } from '@/store';
import { colors } from '@/theme/variables';

// interface NavBarProps {};

const Root = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  boxShadow: 'none',
  borderBottom: '1px solid #FFFFFF1A',
  color: 'white',
  fontWeight: 500,

  '& h5': {
    fontSize: '1.3rem',
    fontWeight: 700,
    color: 'white',

    '& strong': {
      color: colors.primary.main,
    },
  },

  '& .MuiBox-root': {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& img': {
      marginRight: 10,
    },
  },

  '& .MuiTab-root': {
    fontSize: '0.9375rem',
    textTransform: 'capitalize',
    color: 'white!important',
    opacity: 0.6,

    '&.Mui-selected': {
      opacity: 1,
    },
  },
  '& .MuiTabs-indicator': {
    color: colors.primary[500],
  },
  '& .logo': {
    height: 36,
    width: 160,
  },
}));

export const NavBar: FC = () => {
  const { instance: msalInstance } = useMsal();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const localUsername = useAppSelector(selectUsername);

  const onChangeTab = (event: React.SyntheticEvent, value: string) => {
    navigate(value);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    onCloseMenu();
    msalInstance.logoutPopup({
      mainWindowRedirectUri: postLogoutRedirectUrl,
      account: msalInstance.getActiveAccount(),
    });
  };

  return (
    <Root>
      <img src="/images/logo.png" className="logo" />
      <Box display="flex">
        <Tabs value={pathname} onChange={onChangeTab}>
          <Tab label="Accounts" value="/" sx={{ py: 3 }} />
          <Tab label="Agencies" value="/agency" sx={{ py: 3 }} />
        </Tabs>
        <Divider
          orientation="vertical"
          sx={{
            borderColor: 'white',
            width: '1px',
            height: '32px',
            marginLeft: '15px',
            marginRight: '30px',
            opacity: 0.05,
          }}
        />
        <Button
          id="basic-button"
          startIcon={<img src="/images/avatar.png" />}
          endIcon={
            <KeyboardArrowDownIcon
              color="primary"
              fontSize="small"
              sx={{ ml: 0.3 }}
            />
          }
          color="inherit"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          {localUsername}
        </Button>
        <Menu
          open={open}
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          onClose={onCloseMenu}
        >
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Root>
  );
};
