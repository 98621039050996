import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  height: 46,
  width: 300,
  maxWidth: 350,
  borderRadius: 6,
  backgroundColor: alpha('#FFFFFF', 1),
  position: 'relative',
  marginRight: 10,

  '& .MuiInputBase-root': {
    width: '100%',
    height: '100%',
    padding: '9px 10px',
    color: theme.palette.grey[600],
    backgroundColor: 'transparent',
  },
}));

interface SearchBarProps {
  value: string;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onSearch?: (value?: string) => void;
  disabled?: boolean;
}

export const SearchBar: FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder,
  className,
  disabled,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleCancel = () => {
    onChange('');
  };

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      handleCancel();
    }
  };

  return (
    <Root className={className ?? ''}>
      <InputBase
        inputProps={{
          onChange: handleChange,
        }}
        placeholder={placeholder ?? 'Search'}
        onKeyUp={handleKeyUp}
        disabled={disabled}
        startAdornment={<SearchIcon sx={{ mr: 1 }} />}
        value={value}
        endAdornment={
          <CloseIcon
            onClick={handleCancel}
            sx={{
              display: value.length > 0 ? 'block' : 'none',
              cursor: 'pointer',
            }}
          />
        }
      />
    </Root>
  );
};
