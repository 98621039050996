import * as React from 'react';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Button, Stack, styled } from '@mui/material';
import usePagination, { UsePaginationProps } from '@mui/material/usePagination';

import { colors } from '@/theme/variables';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  height: 60,
  marginTop: '0px!important',

  '& li': {
    padding: '15px 9px',
    marginTop: -1,
    '& button': {
      backgroundColor: 'transparent',
      border: 0,
      cursor: 'pointer',
      color: colors.grey[500],
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 500,
    },
  },
  '&>button': {
    height: 0,
    padding: '22px 0px',
    cursor: 'pointer',
    color: colors.grey[500],
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    textTransform: 'capitalize',

    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      opacity: 0.5,
      color: colors.grey[500],
    },
  },
});

export const TablePagination: React.FC<UsePaginationProps> = (props) => {
  const itemsRef = usePagination(props);
  const items = itemsRef?.items ?? [];

  const btnPrevious = items.find((item) => item.type === 'previous');
  const btnNext = items.find((item) => item.type === 'next');

  return (
    <List>
      <Button {...btnPrevious} type="button" startIcon={<ArrowBack />}>
        {btnPrevious?.type}
      </Button>

      <Stack direction="row" justifyContent="center" sx={{ flex: 1 }}>
        {items.map(({ page, type, selected, ...item }, index) => {
          if (type === 'previous' || type === 'next') return null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis')
            return <li key={index}>...</li>;
          return (
            <li
              key={index}
              style={{
                borderTop: selected ? `2px solid ${colors.primary.main}` : 0,
                paddingTop: selected ? 13 : undefined,
              }}
            >
              <button
                type="button"
                style={{
                  color: selected ? colors.primary.main : undefined,
                }}
                {...item}
              >
                {page}
              </button>
            </li>
          );
        })}
      </Stack>

      <Button {...btnNext} type="button" endIcon={<ArrowForward />}>
        {btnNext?.type}
      </Button>
    </List>
  );
};
