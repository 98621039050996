export default {
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        borderBottom: '1px solid #BFC3D1',
        padding: '10px 5px',

        '&.MuiTableCell-head': {
          padding: '20px 5px',
        },
      },
    },
  },
};
